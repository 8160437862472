<template>
  <div class="vendor-container">
    <h4>Edit Business</h4>
    <p>Make Changes to your Business</p>
    <form class="form">
      <div class="mb-3">
        <label>Business Name</label>
        <select
          id="select"
          class="form-select"
          aria-label="Default select example"
          v-model="value.business_type_id"
        >
          <option selected>Select Business Type</option>
          <option value="39db0f4e-2e78-4878-a5c2-f0eea7dbe593">Kitchen</option>
          <option value="5e79cc9c-30ee-4661-9fe7-fcd723f5f0f8">
            Restaurant
          </option>
          <option value="59693cd4-26a9-4df3-9614-15244de08041">Buka</option>
        </select>
      </div>
      <div>
        <label>Business Name</label>
        <div class="input-group input-group mb-3">
          <input
            id="input"
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            v-model="value.business_name"
          />
        </div>
      </div>

      <div>
        <label>Email</label>
        <div class="input-group input-group mb-3">
          <input
            type="text"
            id="input2"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            v-model="value.email"
          />
        </div>
      </div>

      <div>
        <label>Phone Number</label>
        <div class="input-group input-group mb-3">
          <input
            type="number"
            id="input3"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            v-model="value.phone"
          />
        </div>
      </div>

      <div>
        <label>Address Of Business</label>
        <div class="input-group input-group mb-3">
          <input
            type="text"
            id="input3"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            v-model="value.business_address"
          />
        </div>
      </div>

      <div class="row mb-3" id="profile-container">
        <div class="col-md-6">
          <label>State</label>
          <select
            id="select"
            class="form-select"
            aria-label="Default select example"
            v-model="value.state_id"
          >
            <option selected>Select State</option>
            <option value="026617b7-4f42-4edd-80d6-9b9894b667cf">Abia</option>
            <option value="1180b6f6-4058-4309-8488-228a669e2aea">
              Adamawa
            </option>
            <option value="fa2688e0-5611-4863-8b8f-1260acd5f8ae">
              Akwa Ibom
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label>City</label>
          <select
            id="select"
            class="form-select"
            aria-label="Default select example"
            v-model="value.city_id"
          >
            <option selected>Select City</option>
            <option value="525df6bb-0254-45b1-97eb-5679c4e1b1cf">Gwaada</option>
            <option value="d0c222e7-5964-4948-8989-5a5eff8e5062">dalu</option>
            <option value="ffcea021-a6e7-4144-8f8a-7ac4f823b95a">Utako</option>
          </select>
        </div>
      </div>

      <div>
        <label>Submit Business Picture(JPEG or PNG FOMART)</label>
        <div class="input-group input-group mb-3">
          <input
            type="file"
            id="input3"
            class="form-control"
            @change="previewFiles"
            multiple
          />
        </div>
      </div>

      <div class="next">
        <Button id="btn" @click="next" btnText="Save" />
      </div>
    </form>
  </div>
</template>

<script>
import Button from "../../../../components/ReusableUI/SmalllButton.vue";
// import router from "@/router";
import { reactive } from "vue";

export default {
  name: "VendorInput",

  components: {
    Button,
  },

  setup() {
    const value = reactive({
      business_type_id: "",
      business_name: "",
      email: "",
      phone: "",
      state_id: "",
      city_id: "",
      category_ids: "",
      business_address: "",
      file: null,
    });

    return { value };
  },

  methods: {
    // method to get file value
    previewFiles(event) {
      this.file = event.target.files;
    },

    next(event) {
      event.preventDefault();
      const {
        business_type_id,
        business_name,
        email,
        phone,
        state_id,
        business_address,
        city_id,
        category_ids,
      } = this.value;
      const data = {
        business_type_id,
        business_name,
        email,
        phone,
        state_id,
        business_address,
        city_id,
        image: this.file[0],
        category_ids,
      };

      console.log(data);

      // this.$store.state.vendor = data;
      //  router.push({ name: "food-category" });
    },
  },
};
</script>

<style scoped>
#btn {
  margin-top: 4%;
  background-color: rgba(133, 77, 39, 1);
}
.vendor-container {
  padding: 0% 5%;
}
.vendor-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.vendor-container p {
  padding-top: 2%;
}

form {
  padding-top: 20px;
}

#input {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
  margin-right: 0px;
}
#select {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
  margin-right: 0px;
}

#input2 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#input3 {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#state {
  width: 53%;
  /* margin-right: 30%; */
}

#city {
  width: 53%;
  /* margin-right: 30%; */
}

#state[data-v-eaef24a2] {
  width: 100%;
  /* margin-right: 13%;*/
}

.form-control {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

.next {
  text-align: right;
}

@media only screen and (max-width: 700px) {
  #state {
    width: 100%;
  }
}
</style>
